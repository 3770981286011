<script setup>
import { onMounted } from 'vue';
import { getOrCreateDataObject, getOrCreateProcedure, getDataObjectById } from 'o365.vue.ts';
import $t from "o365.modules.translate.ts";
import ODataTable from 'o365.vue.components.DataTable.vue'
import OColumn from 'o365.vue.components.DataGrid.Column.jsx'
import confirm from "o365.controls.confirm.ts";
import HazardsCard from 'sja.vue.components.HazardsCard.vue'
import InitialMatrixModal from 'sja.vue.components.InitialMatrixModal.vue'
import FinalMatrixModal from 'sja.vue.components.FinalMatrixModal.vue'
import { procDeleteTask } from 'sja.vue.modules.utilities.js'
// import { isMobile } from 'mobile.utils.ts';
// import TasksMobileComponent from 'sja.vue.mob.TasksMobile.vue';

const props = defineProps({
    sjaID: String,
    readOnly: Boolean
});

const dsSJA = getOrCreateDataObject({
    id: `dsSJA_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJA',
    maxRecords: -1,
    selectFirstRowOnLoad: true,
    viewName: 'aviw_SJA_SJA',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Step_ID', },
        { name: 'Name', },
        { name: 'Description', },
        { name: 'Planned', },
        { name: 'Completed', },
        { name: 'OrgUnit_ID', },
        { name: 'OrgUnitIdPath', },
        { name: 'OrgUnit', },
        { name: 'Responsible_ID', },
        { name: 'Responsible', },
        { name: 'IsTemplate', },
        { name: 'Checklist_ID', },
        { name: 'CheckList_Editable', },
        { name: 'Checklist', }
    ],
    whereClause: "ID = " + props.sjaID
});

const dsTasks = getOrCreateDataObject({
    id: `dsTasks_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasks',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasks',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJA_ID', },
        { name: 'TaskNo', },
        { name: 'Description', },
        { name: 'Responsible_ID', },
        { name: 'Comment', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
    masterDataObject_ID: `dsSJA_${props.sjaID}`,
    masterDetailDefinition: [
        {
            masterField: "ID",
            detailField: "SJA_ID",
            operator: "equals"
        }
    ],
});

let dsHazards = getOrCreateDataObject({
    id: `dsTasksHazards_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasksHazards',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazards',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Color', },
        { name: 'FinalColor', },
        { name: 'Matrix_ID', },
        { name: 'SJATask_ID', },
        { name: 'FinalMatrix_ID', },
        { name: 'ProbabilityNameAndDesc', },
        { name: 'ConsequenceNameAndDesc', },
        { name: 'FinalProbabilityNameAndDesc', },
        { name: 'FinalConsequenceNameAndDesc', },
        { name: 'ProbabilityValue', },
        { name: 'ConsequenceValue', },
        { name: 'SJA_ID', },
        { name: 'FinalProbabilityValue', },
        { name: 'FinalConsequenceValue', },
        { name: 'ExistingActions', },
        { name: 'Consequences', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
});

let dsActions = getOrCreateDataObject({
    id: `dsTasksHazardsActions_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasksHazardsActions',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazardsActions',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Comment', },
        { name: 'IsVerified', },
        { name: 'SJATasksHazard_ID', },
        { name: 'SJA_ID', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
});

let dsResponsible = getOrCreateDataObject({
    id: `dsTasksHazardsActionsResp_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasksHazardsActionsResponsibles',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazardsActionsResponsibles',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJATasksHazardsAction_ID', },
        { name: 'Responsible_ID', },
        { name: 'StepAction_ID', },
        { name: 'Completed', },
        { name: 'SJA_ID', },
        { name: 'Name', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
});

const dsConsequencesLkp = getOrCreateDataObject({
    id: `dsConsequencesLkp`,
    maxRecords: -1,
    viewName: 'aviw_SJA_Consequences',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Value', },
        { name: 'Description', },
        { name: 'ValueAndName', },
        { name: 'NameAndDescription', },
        { name: 'ValueAndNameAndDescription', }
    ],
});

const dsProbabilitiesLkp = getOrCreateDataObject({
    id: `dsProbabilitiesLkp`,
    maxRecords: -1,
    viewName: 'aviw_SJA_Probabilities',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Value', },
        { name: 'Description', },
        { name: 'ValueAndName', },
        { name: 'NameAndDescription', },
        { name: 'ValueAndNameAndDescription', }
    ],
});

const dsHazardsModalInitial = getOrCreateDataObject({
    id: `dsTasksHazardsModalInitial`,
    uniqueTable: 'atbv_SJA_SJATasksHazards',
    selectFirstRowOnLoad: true,
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazards',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Color', },
        { name: 'FinalColor', },
        { name: 'Matrix_ID', },
        { name: 'SJATask_ID', },
        { name: 'SJA_ID', },
        { name: 'FinalMatrix_ID', },
        { name: 'ProbabilityNameAndDesc', },
        { name: 'ConsequenceNameAndDesc', },
        { name: 'FinalProbabilityNameAndDesc', },
        { name: 'FinalConsequenceNameAndDesc', },
        { name: 'ProbabilityValue', },
        { name: 'ConsequenceValue', },
        { name: 'FinalProbabilityValue', },
        { name: 'FinalConsequenceValue', }
    ],
});

const dsHazardsModalFinal = getOrCreateDataObject({
    id: `dsTasksHazardsModalFinal`,
    uniqueTable: 'atbv_SJA_SJATasksHazards',
    selectFirstRowOnLoad: true,
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazards',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Color', },
        { name: 'FinalColor', },
        { name: 'Matrix_ID', },
        { name: 'SJATask_ID', },
        { name: 'SJA_ID', },
        { name: 'FinalMatrix_ID', },
        { name: 'ProbabilityNameAndDesc', },
        { name: 'ConsequenceNameAndDesc', },
        { name: 'FinalProbabilityNameAndDesc', },
        { name: 'FinalConsequenceNameAndDesc', },
        { name: 'ProbabilityValue', },
        { name: 'ConsequenceValue', },
        { name: 'FinalProbabilityValue', },
        { name: 'FinalConsequenceValue', },
        { name: 'ProbabilityValueID', },
        { name: 'ConsequenceValueID', },
        { name: 'FinalProbabilityValueID', },
        { name: 'FinalConsequenceValueID', }
    ],
});

dsTasks.on("AfterSave", async function (options, row) {
    if (options.operation === "create") {
        dsHazards.load()
        newRow(row, 'Hazard')
    }
});

dsTasks.on("DataLoaded", async function (options, row) {
    dsHazards.load();
    dsActions.load();
    dsResponsible.load();
});

dsTasks.on("BeforeDelete", async function (options, row) {
    options.cancelEvent = true;
    await confirm({
        title: $t("Delete Task"),
        message: $t('Are you sure you wish to delete') + ' ' + row.Description + '?',
        btnTextOk: $t("Yes"),
        btnTextCancel: $t("No"),
    }).then(async () => {
        await procDeleteTask.execute({ PrimKey: row.PrimKey });
        dsTasks.load();
    }).catch(() => {
    });
});

onMounted(async () => {
    await dsSJA.load();
    await dsTasks.load();
    if (props.readOnly === true) {
        document.querySelector(`.tasks-table-${props.sjaID} [data-o365-container="N"]`).classList.add("d-none");
    }
})

const procCreateHazardRow = getOrCreateProcedure({ id: "procCreateHazardRow", procedureName: "astp_SJA_CreateHazardRow" });

async function newRow(row) {
    let dsTasksHazards = null;
    let dsTasksHazardsActions = null;
    let dsTasksHazardsActionsResp = null;
    try {
        await procCreateHazardRow.execute({ ID: row.ID });
        dsTasksHazards = getDataObjectById(`dsTasksHazards_${row.SJA_ID}`);
        dsTasksHazardsActions = getDataObjectById(`dsTasksHazardsActions_${row.SJA_ID}`);
        dsTasksHazardsActionsResp = getDataObjectById(`dsTasksHazardsActionsResp_${row.SJA_ID}`);
        await dsTasksHazards.load();
        await dsTasksHazardsActions.load();
        await dsTasksHazardsActionsResp.load();
    } catch (error) {
        console.error("Error loading data:", error);
    }
}

</script>

<template>
    <!-- <template v-if="isMobile"> -->
    <!-- <TasksMobileComponent :sjaID="props.sjaID" :readOnly="props.readOnly"/> -->
    <!-- </template> -->
    <!-- <template v-else> -->
    <ODataTable :class="`p-0 tasks-table-${props.sjaID}`" :hideNewRecords="props.readOnly" :dataObject="dsTasks"
        disableColumnMove hideMultiselectColumn noActiveRows style="min-width:930px" :hideSystemColumn="props.readOnly"
        :hideActionColumn="props.readOnly" :noFooter="props.readOnly" hideGridMenu load-data-object>
        <OColumn sortable disableResize disableMenu disableColumnMove width="60px" field="TaskNo" v-slot="{ row: task }"
            required :headerName="$t('No.') + (props.readOnly == true ? '' : ' *')">
            <OContentEditable placeholder="*" :isEditable="!props.readOnly" class="w-100 h-100 p-1 text-break"
                :class="{ 'inner-table-editable': !props.readOnly }" v-model="task.TaskNo"
                @blur="if (task.TaskNo && task.Description) { dsTasks.save() }; " />
        </OColumn>
        <OColumn disableResize disableMenu disableColumnMove flexwidth="5" width="150px" field="Description"
            v-slot="{ row: task }" required :headerName="$t('Description') + (props.readOnly == true ? '' : ' *')">
            <OContentEditable placeholder="*" :isEditable="!props.readOnly" class="w-100 h-100 p-1 text-break"
                :class="{ 'inner-table-editable': !props.readOnly }" v-model="task.Description"
                @blur="if (task.TaskNo && task.Description) { dsTasks.save() };" />
        </OColumn>
        <OColumn disableResize disableMenu disableColumnMove flexwidth="95" class="custom-cell"
            colId="HazardSubCol" :headerName="$t('Hazards and Actions')" v-slot="{ row: taskRow }">
            <div style="max-width:1500px">
                <HazardsCard :taskRow="taskRow" :sjaID="props.sjaID" :readOnly="props.readOnly" />

                <div v-if="!props.readOnly && !taskRow.isNewRecord" class="text-center">
                    <button :title="$t('Add a new hazard to task.')" class="btn btn-sm btn-primary px-2 p-1 m-1"
                        ref="newHazardRow" type="button" @click="newRow(taskRow, 'Hazard')">
                        <i :title="$t('Add a new hazard.')" class="text-white bi bi-plus-lg"></i>
                        <span class="text-white">{{ $t('New Hazard') }}</span>
                    </button>
                </div>
            </div>
        </OColumn>
    </ODataTable>
    <!-- </template> -->
    <InitialMatrixModal></InitialMatrixModal>
    <FinalMatrixModal></FinalMatrixModal>
</template>

<style scoped>
.inner-table-editable {
    border: 1.5px solid var(--o365-grid-border) !important;
    border-radius: var(--bs-border-radius) !important;
}

.inner-table-editable:focus,
.inner-table-editable:hover {
    outline: 0;
    border: 1.5px solid rgba(70, 130, 180, .25) !important;
    box-shadow: 0px 0px 0px 2px rgba(70, 130, 180, .25) !important;
}</style>